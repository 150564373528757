import React from "react";
import "./App.css";
import Login from "./components/Login/Login";
import Modal from "react-modal";

function App() {
  Modal.setAppElement("#root");

  return (
    <main className="main">
      <div className="container">
        <Login />
      </div>
    </main>
  );
}

export default App;
