// Create a new file Login.js
import React from "react";
import "./Login.css";

const Login = () => {

  return (
    <>
      <div
        className="login-container"
        style={{ backgroundImage: 'url("images/google.png")' }}
      >
        <div class="getting-started-main-container">
          <section class="getting-started-main-container_items_head">
            <h2>AI Powered Dialogflow Agents.</h2>
            <span>by Zazmic</span>
          </section>
        </div>
      </div>
    </>
  );
};

export default Login;
